@font-face {
  font-family: "PPWriter-RegularItalic";
  src: url("./fonts/PPWriter-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PPWriter-Regular";
  src: url("./fonts/PPWriter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPTelegraf-Regular";
  src: url("./fonts/PPTelegraf-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

.rfm-marquee:after,
.rfm-child::after {
  content: "";
  display: table;
  clear: both;
}
